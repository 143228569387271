import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '../../atoms/button';
import VideoListSwiper from './video-list-swiper';
import LmsContext from './context';

const LearningModuleCard = ({
  title,
  slug,
  image,
  description,
  videoList,
  bookingLink,
  courseTitle,
}) => {
  const { locale } = useContext(LmsContext);
  const accordion = useRef(null);
  const [accordionHeight, setAccordionHeight] = useState(0);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const img = getImage(image);

  const toggleAccordion = () => {
    setAccordionHeight(accordion.current.offsetHeight);
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="w-full bg-white rounded-lg">
      <div className="w-full py-3 pl-3 pr-3 lg:pr-9">
        <div className="relative flex flex-col lg:flex-row justify-between items-center">
          <div className="w-full lg:w-2/5">
            <div className="aspect-w-16 aspect-h-10 rounded-lg overflow-hidden mobile+tablet:mb-7">
              <div>
                <GatsbyImage
                  image={img}
                  alt={image.description || image.title || ''}
                />
              </div>
            </div>
          </div>

          <div className="w-full lg:w-[calc(60%-50px)]">
            <div className="mb-6">
              <p className="uppercase text-body-md font-bold text-blue-bold">
                {courseTitle}
              </p>
              <h2 className="text-h3 text-black font-medium mt-1.5">{title}</h2>
            </div>

            <p className="u-body-md text-black mb-6">{description}</p>

            <div className="w-full flex flex-col lg:flex-row justify-between items-center lg:items-end">
              <div className="flex gap-x-3 mobile+tablet:mb-8">
                <Button
                  as="a"
                  label="Module Overview"
                  href={`/academy${locale === 'en-NZ' ? '/nz' : ''}/${slug}`}
                />
                {bookingLink && (
                  <Button
                    variant="secondary"
                    as="a"
                    newTab
                    href={bookingLink}
                    label="Book training"
                  />
                )}
              </div>

              <button
                type="button"
                className="text-body-md text-blue-navy underline flex items-center hover:md:no-underline"
                onClick={toggleAccordion}
              >
                <span className="mr-3">See all {videoList.length} topics</span>

                <svg
                  width="20"
                  height="12"
                  viewBox="0 0 20 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`transition-transform duration-200 ${
                    isAccordionOpen && '-rotate-180'
                  }`}
                >
                  <path
                    className="stroke-current"
                    strokeWidth="2"
                    d="M19 1L10 10L1 1"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full overflow-hidden transition-all duration-300"
        style={{
          height:
            isAccordionOpen && accordionHeight !== 0 ? accordionHeight : 0,
        }}
      >
        <div
          ref={accordion}
          className="pb-0 pt-8 md:pb-8 pl-3 pr-11 overflow-hidden"
        >
          <VideoListSwiper
            parentSlug={slug}
            videoList={videoList}
            headingHiddenOnMobile
          />
        </div>
      </div>
    </div>
  );
};

LearningModuleCard.defaultProps = {
  bookingLink: null,
};

LearningModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  videoList: PropTypes.array.isRequired,
  bookingLink: PropTypes.string,
  courseTitle: PropTypes.string.isRequired,
};

export default LearningModuleCard;
